exports.components = {
  "component---src-containers-contact-us-tsx": () => import("./../../../src/containers/ContactUs.tsx" /* webpackChunkName: "component---src-containers-contact-us-tsx" */),
  "component---src-containers-home-tsx": () => import("./../../../src/containers/Home.tsx" /* webpackChunkName: "component---src-containers-home-tsx" */),
  "component---src-containers-legal-home-tsx": () => import("./../../../src/containers/Legal/Home.tsx" /* webpackChunkName: "component---src-containers-legal-home-tsx" */),
  "component---src-containers-legal-privacy-policy-tsx": () => import("./../../../src/containers/Legal/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-containers-legal-privacy-policy-tsx" */),
  "component---src-containers-legal-terms-of-use-tsx": () => import("./../../../src/containers/Legal/TermsOfUse.tsx" /* webpackChunkName: "component---src-containers-legal-terms-of-use-tsx" */),
  "component---src-containers-orders-create-manual-store-orders-tsx": () => import("./../../../src/containers/Orders/CreateManualStoreOrders.tsx" /* webpackChunkName: "component---src-containers-orders-create-manual-store-orders-tsx" */),
  "component---src-containers-orders-create-manual-user-orders-tsx": () => import("./../../../src/containers/Orders/CreateManualUserOrders.tsx" /* webpackChunkName: "component---src-containers-orders-create-manual-user-orders-tsx" */),
  "component---src-containers-orders-home-tsx": () => import("./../../../src/containers/Orders/Home.tsx" /* webpackChunkName: "component---src-containers-orders-home-tsx" */),
  "component---src-containers-orders-payment-methods-tsx": () => import("./../../../src/containers/Orders/PaymentMethods.tsx" /* webpackChunkName: "component---src-containers-orders-payment-methods-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-version-tsx": () => import("./../../../src/pages/version.tsx" /* webpackChunkName: "component---src-pages-version-tsx" */)
}

