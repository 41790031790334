import React, { ReactNode } from 'react'

import {
  PageContextProvider,
  type PageContextType,
} from '@HOCs/Providers/PageContext'

// TODO: useAuth and Providers/Auth should be combine.
import { AuthProvider } from '@hooks/useAuth'
import Auth from '@HOCs/Providers/Auth'

interface Props {
  children: ReactNode
  context: PageContextType
}

function Providers({ children, context }: Props) {
  return (
    <PageContextProvider context={context}>
      <AuthProvider>
        <Auth>{children}</Auth>
      </AuthProvider>
    </PageContextProvider>
  )
}

export default Providers
