import React from 'react'

import { ThemeProvider } from '@wicadu/arepa/ui'
import Providers from '@HOCs/Providers'

export const onInitialClientRender = () => {
  const fontLinks = [
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/icon?family=Material+Icons',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Urbanist:wght@400;700&display=swap',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Catamaran&display=swap',
    },
  ]

  fontLinks.forEach(({ rel, href, crossOrigin }) => {
    if (!document.querySelector(`link[href="${href}"]`)) {
      const link = document.createElement('link')
      link.rel = rel
      link.href = href
      if (crossOrigin) link.crossOrigin = crossOrigin
      document.head.appendChild(link)
    }
  })
}

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider
    mode="LIGHT"
    fontOptions={{
      load: false,
      enableSwap: true,
    }}
  >
    <Providers context={props?.pageContext}>{element}</Providers>
  </ThemeProvider>
)
