import React, { useCallback, useMemo } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { navigate } from 'gatsby'
import { useFetch } from '@wicadu/arepa/hooks'
import useAuth from '../../hooks/useAuth'
import LoadingTemplate from '../../components/templates/Loading'
import { isBrowser } from '@wicadu/arepa/utils'

const propTypes = {
  children: PropTypes.node.isRequired,
}

type Props = InferProps<typeof propTypes>

function Auth({ children }: Props) {
  const {
    whoAmILoading,
    isAccessTokenRestored,
    accessToken,
    isProfileRestored,
    isProfileRestoredUnsuccessfully,
    restoreUser,
    restoreUserFailed,
    signOut,
  } = useAuth()

  const skip = useMemo(
    () => !isAccessTokenRestored || !accessToken || isProfileRestored || isProfileRestoredUnsuccessfully, [
      isAccessTokenRestored,
      accessToken,
      isProfileRestored,
      isProfileRestoredUnsuccessfully,
    ]
  )

  const onCompleted = useCallback((whoAmI: any) => {
    restoreUser(whoAmI)
  }, [restoreUser])

  const onError = useCallback(
    ({ message }: any) => {
      if (message === 'Unauthorized') return signOut()
      if (message === 'check-verification-code-is-required')
        return console.log('tokenIsNotVerified')

      restoreUserFailed()
      console.log('networkErrorAlert')
    },
    [signOut, restoreUserFailed]
  )

  // This request should point to `HELP_SERVER_HOST`
  useFetch(`${process.env.STORES_SERVER_HOST}/users/who-am-i`, {
    fetchOptions: {
      method: 'GET',
      headers: isBrowser() ? new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }) : {},
    },
    onError,
    onCompleted,
    skip,
  })

  if (!isAccessTokenRestored || (isAccessTokenRestored && Boolean(accessToken) && whoAmILoading))
    return <LoadingTemplate />

  if (isProfileRestoredUnsuccessfully && !isProfileRestored) {
    navigate('/500')
    return <LoadingTemplate />
  }

  return children
}

export default Auth
