import React, { createContext, useContext } from 'react'

import { Languages } from '@ts-types/Translations'

export interface PageContextType {
  lang: Languages
}

const PageContext = createContext<PageContextType & Record<string, unknown>>({
  lang: 'es',
})

interface Props<T extends Record<string, unknown> = {}> {
  children: React.ReactNode | React.ReactElement
  context: PageContextType & T
}

export function PageContextProvider<T extends Record<string, unknown> = {}>({
  children,
  context,
}: Props<T>) {
  return (
    <PageContext.Provider value={{ ...context }}>
      {children}
    </PageContext.Provider>
  )
}

export function usePageContext<T extends Record<string, unknown> = {}>() {
  return useContext(PageContext) as PageContextType & T
}
