import { createContext, useContext } from 'react'

import Provider from './Provider'

export type IAuthState = {
  isSignedIn: boolean
  accessToken: string
  isAccessTokenRestored: boolean
  isProfileRestored: boolean
  isProfileRestoredUnsuccessfully: boolean
  profile: any
  whoAmILoading: boolean
}

export type IAuthUtils = {
  login: () => void
  restoreToken: (accessToken: string) => void
  restoreUser: (profile: any) => void
  restoreUserFailed: () => void
  whoAmIIsLoading: (loading: boolean) => void
  signOut: () => void
}

type IAuthAction =
  | { type: 'RESTORE_USER', payload: { profile: IAuthState['profile'] } }
  | { type: 'RESTORE_USER_FAILED' }
  | { type: 'RESTORE_TOKEN', payload: { accessToken: IAuthState['accessToken'] } }
  | { type: 'WHO_AM_I_IS_LOADING', payload: { loading: boolean } }

type IAuthContext = IAuthState & IAuthUtils

export const stateDefault: IAuthState = {
  accessToken: '',
  isAccessTokenRestored: false,
  isSignedIn: false,
  isProfileRestored: false,
  isProfileRestoredUnsuccessfully: false,
  profile: {
    oAuthUser: null,
  },
  whoAmILoading: true,
}

export const reducers = (prevState: IAuthState, action: IAuthAction) => {
  switch (action.type) {
    case 'RESTORE_USER':
      return {
        ...prevState,
        isSignedIn: true,
        profile: {
          ...prevState.profile,
          ...action.payload.profile
        },
        isProfileRestored: true,
        isProfileRestoredUnsuccessfully: false,
        whoAmILoading: false
      }
    case 'RESTORE_USER_FAILED':
      return {
        ...prevState,
        isSignedIn: false,
        isProfileRestored: false,
        isProfileRestoredUnsuccessfully: true,
        whoAmILoading: false
      }
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        isAccessTokenRestored: true,
        accessToken: action.payload.accessToken
      }
    case 'WHO_AM_I_IS_LOADING':
      return {
        ...prevState,
        whoAmILoading: action.payload.loading
      }
  }
}

export const AuthProvider = Provider

export const AuthContext = createContext<any>({})
export default (): IAuthContext => useContext(AuthContext)
